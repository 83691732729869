// 过滤器
export const filters = {
  formatPrice: value => {
  if(value === 0){
    return 0.00
  }
      if(!value){
          return;
      }
  return    ((+value).toFixed(2));
  },
}