import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  //登录页
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login/login.vue'),
  },
  //中转页
  {
    path: '/transfer',
    name: 'transfer',
    component: () => import('../views/Transfer.vue'),
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import('../views/attendClass/demo2.vue'),
  },
  {
    path: '/demo3',
    name: 'demo3',
    component: () => import('../views/attendClass/demo3.vue'),
  },
  //老师端
  {
    path: '/teacher',
    component: () => import('../views/Main.vue'),
    children: [
      {
        path: '/teacher/teachPlan',
        name: 'teachPlan',
        component: () => import('../views/teacher/teachPlan.vue'),
      },
      {
        path: '/teacher/coursewareLibrary',
        name: 'coursewareLibrary',
        component: () => import('../views/teacher/coursewareLibrary.vue'),
      }
    ]
  },
  //学生端
  {
    path: '/student',
    component: () => import('../views/Main.vue'),
    children: [
      {
        path: '/student/studyPlan',
        name: 'studyPlan',
        component: () => import('../views/student/studyPlan.vue'),
      },
      {
        path: '/student/appointmentClass',
        name: 'appointmentClass',
        component: () => import('../views/student/appointmentClass.vue'),
      }, 
     
      {
        path: '/student/buyClass',
        name: 'buyClass',
        component: () => import('../views/student/buyClass.vue'),
      },
      {
        path: '/student/studentOrder',
        name: 'studentOrder',
        component: () => import('../views/student/studentOrder.vue'),
      },
      {
        path: '/student/learningReport',
        name: 'learningReport',
        component: () => import('../views/student/learningReport.vue'),
      },
      {
        path: '/student/studentNotifica',
        name: 'studentNotifica',
        component: () => import('../views/student/studentNotifica.vue'),
      }

    ]
  },
  //上课
  {
    path: '/attendClass',
    component: () => import('../views/Main.vue'),
    children: [
      {
        path: '',
        name: 'attendClass',
        component: () => import('../views/attendClass/room.vue'),
      },

    ]
  },
  // 个人中心
  {
    path: '/user',
    component: () => import('../views/Main.vue'),
    children: [
      {
        path: '/personalCenter',
        name: 'personalCenter',
        component: () => import('../views/personalCenter.vue'),
      }
    ]
  },
  // 404
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/error/404.vue'),
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

// 判断是否登录
// 登录白名单
const whiteList = ['/', '/transfer']
router.beforeEach((to, from, next) => {
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
  // if (!userInfo) {
  //   console.log('没有登录');
  //   if (whiteList.indexOf(to.path) !== -1) {
  //     next();
  //   } else {
  //     next('/');
  //   }
  // }
  next();
});

export default router;
